/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*Photo by <a href="https://unsplash.com/@jeshoots?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">JESHOOTS.COM</a> on <a href="https://unsplash.com/s/photos/math?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>*/
/*prettier-ignore*/
/*prettier-ignore*/
/*prettier-ignore*/
/*prettier-ignore*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {MathBlock} from "./../../../src/components/MathBlock";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ol: "ol",
    li: "li",
    a: "a",
    code: "code",
    br: "br",
    h2: "h2",
    pre: "pre",
    ul: "ul",
    h3: "h3",
    strong: "strong",
    h4: "h4",
    em: "em",
    span: "span",
    div: "div"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR"), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Add ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/remark-math"
  }, React.createElement(_components.code, null, "remark-math")), "."), "\n", React.createElement(_components.li, null, "Intercept the math and render it with ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/@matejmazur/react-katex"
  }, React.createElement(_components.code, null, "react-katex")), "."), "\n"), React.createElement(_components.p, null, "Show me a minimal ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/gatsby-mdx-math-example"
  }, "code example"), "."), React.createElement(_components.p, null, "I’d like to see ", React.createElement(_components.a, {
    href: "https://nickymeuleman.github.io/gatsby-mdx-math-example/"
  }, "a demo"), " of that code example in action!")), "\n", React.createElement(_components.p, null, "I wanted to add support to display equations on my blog.\nA popular tool to achieve this is ", React.createElement(_components.a, {
    href: "https://katex.org/"
  }, "KaTeX"), "."), "\n", React.createElement(_components.p, null, "The goal is being able to write a string in the TeX syntax inside a ", React.createElement(_components.code, null, ".mdx"), " file,\nand a pretty math equation showing up on the rendered page."), "\n", React.createElement(_components.p, null, "My blog uses ", React.createElement(_components.a, {
    href: "https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-plugin-mdx"
  }, React.createElement(_components.code, null, "gatsby-plugin-mdx")), " to be able to use ", React.createElement(_components.code, null, ".mdx"), " files."), "\n", React.createElement(_components.p, null, "To achieve the goal of writing KaTeX blocks in MDX and getting pretty equations to show up, several things need to happen."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-plugin-mdx"
  }, React.createElement(_components.code, null, "gatsby-plugin-mdx")), " allows you to pass plugins it will use internally.", React.createElement(_components.br), "\n", "We’ll use that to “make pretty math happen” 💅!"), "\n", React.createElement(_components.h2, {
    id: "pinpoint-the-math-sections-in-mdx"
  }, "Pinpoint the math sections in mdx"), "\n", React.createElement(_components.p, null, "The first step is getting the MDX toolchain to recognize the math inside a ", React.createElement(_components.code, null, ".mdx"), " file."), "\n", React.createElement(_components.p, null, "I used the ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/remark-math"
  }, React.createElement(_components.code, null, "remark-math")), " plugin for this.\nIt takes your markdown, looks for the relevant math sections, and marks them as such."), "\n", React.createElement(_components.p, null, "An inline math section is opened with a single dollar sign, and ended by an other single dollar sign ", React.createElement(_components.code, null, "$"), ".", React.createElement(_components.br), "\n", "A math section that should be a block (so: it should start on a new line) is opened and closed by double dollar signs ", React.createElement(_components.code, null, "$$"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-mdx",
    title: "index.mdx",
    numberLines: true
  }, "Some math on the same line: $T_n = a + (n-1)d$\n\nOr, some math in a block\n\n$$\nT_n = a + (n-1)d\n$$\n")), "\n", React.createElement(_components.p, null, "The way markdown works has to do with ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Abstract_syntax_tree"
  }, "Abstract syntax trees"), ".\nThey’re terribly complex at times, but they’re also very fascinating and extremely powerful tools."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/remark-math"
  }, React.createElement(_components.code, null, "remark-math")), " manipulates that tree in such a way that your inline math turns into an ", React.createElement(_components.code, null, "inlineMath"), " node,\nand your block math turns into a ", React.createElement(_components.code, null, "math"), " node."), "\n", React.createElement(_components.p, null, "At the end of the MDX pipeline, HTML is rendered."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The inline math blocks will turn into ", React.createElement(_components.code, null, "span"), "s with a class of ", React.createElement(_components.code, null, "math-inline"), "."), "\n", React.createElement(_components.li, null, "A math block will turn into a ", React.createElement(_components.code, null, "div"), " with a class of ", React.createElement(_components.code, null, "math-display"), "."), "\n"), "\n", React.createElement(_components.p, null, "The content of those HTML-elements will be a string with the ", React.createElement(_components.a, {
    href: "https://katex.org/docs/supported.html"
  }, "TeX"), " you wrote in the ", React.createElement(_components.code, null, ".mdx"), " file."), "\n", React.createElement(_components.p, null, "So ", React.createElement(_components.code, null, "$T_n = a + (n-1)d$"), " turns into ", React.createElement(_components.code, null, "<span class=\"math math-inline\">T_n = a + (n-1)d</span>")), "\n", React.createElement(_components.p, null, "While the block"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-mdx",
    title: "index.mdx",
    numberLines: true
  }, "$$\nT_n = a + (n-1)d\n$$\n")), "\n", React.createElement(_components.p, null, "Turns into ", React.createElement(_components.code, null, "<div class=\"math math-display\">T_n = a + (n-1)d</div>")), "\n", React.createElement(_components.h3, {
    id: "wiring-up-remark-math"
  }, "Wiring up remark-math"), "\n", React.createElement(Aside, {
    variant: "danger"
  }, React.createElement(_components.p, null, "Make sure to install version 3 of ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/remark-math"
  }, React.createElement(_components.code, null, "remark-math")), " if you are using ", React.createElement(_components.a, {
    href: "https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-plugin-mdx"
  }, React.createElement(_components.code, null, "gatsby-plugin-mdx")), ".\nInternally, the mdx plugin uses version 10 of ", React.createElement(_components.a, {
    href: "https://github.com/remarkjs/remark"
  }, React.createElement(_components.code, null, "remark")), ", while version 4 of ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/remark-math"
  }, React.createElement(_components.code, null, "remark-math")), " requires version 13 of ", React.createElement(_components.a, {
    href: "https://github.com/remarkjs/remark"
  }, React.createElement(_components.code, null, "remark")), "."), React.createElement(_components.p, null, "YAY dependencies!"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm i remark-math@3.0.1\n"))), "\n", React.createElement(_components.p, null, "Install the ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/remark-math"
  }, React.createElement(_components.code, null, "remark-math")), " plugin, and add it to the configuration option of ", React.createElement(_components.a, {
    href: "https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-plugin-mdx"
  }, React.createElement(_components.code, null, "gatsby-plugin-mdx")), " in ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/reference/config-files/gatsby-config/"
  }, React.createElement(_components.code, null, "gatsby-config.js")), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    title: "gatsby-config.js",
    hl: "7",
    numberLines: true
  }, "module.exports = {\n  // --- snip ---\n  plugins: [\n    {\n      resolve: \"gatsby-plugin-mdx\",\n      options: {\n        remarkPlugins: [require(\"remark-math\")],\n      },\n    },\n    // --- snip ---\n  ],\n};\n")), "\n", React.createElement(_components.h2, {
    id: "doing-something-with-that-information"
  }, "Doing something with that information"), "\n", React.createElement(_components.p, null, "There are several ways to continue from here.\nWichever way you choose, adding the required KaTeX CSS file whenever math is rendered is crucial."), "\n", React.createElement(Aside, {
    variant: "danger"
  }, React.createElement(_components.p, null, "The KaTeX CSS file needs to be imported on a page to render the math correctly."), React.createElement(_components.p, null, "You can do this manually in every mdx file, or once in a layout file, or in ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/"
  }, React.createElement(_components.code, null, "gatsby-browser.js")), ",…"), React.createElement(_components.p, null, "For example, via the import style in a ", React.createElement(_components.code, null, ".mdx"), " or ", React.createElement(_components.code, null, ".js"), " file"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import \"katex/dist/katex.min.css\";\n")), React.createElement(_components.p, null, "Or via the require style inside ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/"
  }, React.createElement(_components.code, null, "gatsby-browser.js"))), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "require(\"katex/dist/katex.min.css\");\n")), React.createElement(_components.p, null, "Or a ", React.createElement(_components.code, null, "<link>"), " tag in an HTML file:"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html"
  }, "<link\n  rel=\"stylesheet\"\n  href=\"https://cdn.jsdelivr.net/npm/katex@0.12.0/dist/katex.min.css\"\n  integrity=\"sha384-AfEj0r4/OFrOo5t7NnNe46zW/tFgW6x/bCJG8FqQCEo3+Aro6EYUG4+cU+KJWu/X\"\n  crossorigin=\"anonymous\"\n/>\n"))), "\n", React.createElement(_components.h3, {
    id: "option-1-a-rehype-plugin"
  }, "Option 1: a Rehype plugin"), "\n", React.createElement(_components.p, null, "While a remark plugin like ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/remark-math"
  }, React.createElement(_components.code, null, "remark-math")), " does work on the ", React.createElement("abbr", {
    title: "Abstract syntax tree"
  }, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Abstract_syntax_tree"
  }, "AST")), " of the mdx ", React.createElement(_components.strong, null, "before"), " it turned into HTML."), "\n", React.createElement(_components.p, null, "A rehype plugin does work ", React.createElement(_components.strong, null, "after"), " that HTML is generated and changes it."), "\n", React.createElement(_components.p, null, "You can use ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/rehype-katex"
  }, React.createElement(_components.code, null, "rehype-katex")), " for this."), "\n", React.createElement(_components.p, null, "It takes in those ", React.createElement(_components.code, null, "<span class=\"math-inline\">"), " and ", React.createElement(_components.code, null, "<div class=\"math-display\">"), " and turns the string of KaTeX you wrote\ninto something that’s ready to be rendered into a beautiful equation.\nIt just needs some of that CSS."), "\n", React.createElement(_components.h4, {
    id: "wiring-up-rehype-katex"
  }, "Wiring up rehype-katex"), "\n", React.createElement(_components.p, null, "Install the ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/rehype-katex"
  }, React.createElement(_components.code, null, "rehype-katex")), " plugin,\nand add it to the configuration option of ", React.createElement(_components.a, {
    href: "https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-plugin-mdx"
  }, React.createElement(_components.code, null, "gatsby-plugin-mdx")), " inside the ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/reference/config-files/gatsby-config/"
  }, React.createElement(_components.code, null, "gatsby-config.js")), " file."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm i rehype-katex\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    title: "gatsby-config.js",
    hl: "8",
    numberLines: true
  }, "module.exports = {\n  // --- snip ---\n  plugins: [\n    {\n      resolve: \"gatsby-plugin-mdx\",\n      options: {\n        remarkPlugins: [require(\"remark-math\")],\n        rehypePlugins: [require(\"rehype-katex\")],\n      },\n    },\n    // --- snip ---\n  ],\n};\n")), "\n", React.createElement(_components.p, null, "If you chose option 1, you’re done!\nPretty math, yay!"), "\n", React.createElement(_components.h3, {
    id: "option-2-a-react-component"
  }, "Option 2: a React component"), "\n", React.createElement(_components.p, null, "I like the React workflow, so this is the option I chose."), "\n", React.createElement(_components.p, null, "It’s a bit more flexible, Should I ever want to use it outside of mdx, I can."), "\n", React.createElement(_components.p, null, "I take in those ", React.createElement(_components.code, null, "<span class=\"math-inline\">"), " and ", React.createElement(_components.code, null, "<div class=\"math-display\">"), " elements,\nand give the string they contain to a React component to render correctly."), "\n", React.createElement(_components.p, null, "The React package I went with is ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/@matejmazur/react-katex"
  }, "@matejmazur/react-katex\n"), ".\nIt has a ", React.createElement(_components.code, null, "<TeX />"), " component that can turn the string of raw KaTeX into a form that’s ready to turn into a pretty equation on your screen."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "I also chose this method because I wanted to create a custom component.\nMake it pop ✨ with some custom CSS, and add some extra features like giving a math block a title.")), "\n", React.createElement(_components.h4, {
    id: "using-react-katex"
  }, "Using ", React.createElement(_components.code, null, "react-katex")), "\n", React.createElement(_components.p, null, "MDX allows you to intercept the HTML that gets rendered and add to, or replace it."), "\n", React.createElement(_components.p, null, "This is the mechanism I used to intercept those ", React.createElement(_components.code, null, "<span class=\"math-inline\">"), " and ", React.createElement(_components.code, null, "<div class=\"math-display\">"), " tags,\nand give the contents to the ", React.createElement(_components.code, null, "<TeX />"), " component."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://mdxjs.com/getting-started#mdxprovider"
  }, React.createElement(_components.code, null, "<MdxProvider />")), " component from ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/@mdx-js/react"
  }, React.createElement(_components.code, null, "@mdx-js/react")), " allows this.\nIf you already have one of those in your app that wraps the ", React.createElement(_components.code, null, ".mdx"), " files you want to write math in, great!"), "\n", React.createElement(_components.p, null, "If not, you can add one in Gatsby’s ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapRootElement"
  }, React.createElement(_components.code, null, "wrapRootElement")), " in both ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/"
  }, React.createElement(_components.code, null, "gatsby-browser.js")), " and ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/reference/config-files/gatsby-ssr/"
  }, React.createElement(_components.code, null, "gatsby-ssr.js")), ".\nOr, in the ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/plugins/gatsby-plugin-mdx/#default-layouts"
  }, "layout component"), " the ", React.createElement(_components.code, null, ".mdx"), " file will use."), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Yes, I know, there are ", React.createElement(_components.strong, null, "a lot"), " of moving parts here.")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    title: "MdxLayout.js",
    numberLines: true
  }, "import React from \"react\";\nimport TeX from \"@matejmazur/react-katex\";\nimport { MDXProvider } from \"@mdx-js/react\";\n\nconst components = {\n  div: (props) => {\n    if (props.className.includes(\"math-display\")) {\n      import(\"katex/dist/katex.min.css\");\n      return <TeX block math={props.children} />;\n    }\n    return <div {...props} />;\n  },\n  span: (props) => {\n    if (props.className.includes(\"math-inline\")) {\n      import(\"katex/dist/katex.min.css\");\n      return <TeX math={props.children} />;\n    }\n    return <span {...props} />;\n  },\n};\n\nexport default function MdXLayout(props) {\n  return <MDXProvider components={components}>{props.children}</MDXProvider>;\n}\n")), "\n", React.createElement(_components.h2, {
    id: "demo"
  }, "Demo"), "\n", React.createElement(_components.p, null, "For this blog, I chose the option that uses a React component."), "\n", React.createElement(_components.p, null, "I created a specialized ", React.createElement(_components.code, null, "<MathBlock />"), " component that handles math blocks,\nand gives it some extra capabilities you can specify inside mdx, by adding options next to the opening ", React.createElement(_components.code, null, "$$"), "."), "\n", React.createElement(Aside, {
    variant: "success"
  }, React.createElement(_components.p, null, "Pretty math blocks that look like my code blocks! ✨✨")), "\n", React.createElement(_components.p, null, "Inline math expressions are sent straight to the ", React.createElement(_components.code, null, "<TeX />"), " component."), "\n", React.createElement(_components.p, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, "a"), " = first item", React.createElement(_components.br), "\n", React.createElement(_components.span, {
    className: "math math-inline"
  }, "l"), " = last item", React.createElement(_components.br), "\n", React.createElement(_components.span, {
    className: "math math-inline"
  }, "n"), " = amount of items", React.createElement(_components.br), "\n", React.createElement(_components.span, {
    className: "math math-inline"
  }, "d"), " = common difference"), "\n", React.createElement(_components.p, null, "Input:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-mdx",
    title: "index.mdx",
    numberLines: true
  }, "Some inline math, coming right up. $T_n = a + (n-1)d$\n")), "\n", React.createElement(_components.p, null, "Output:"), "\n", React.createElement(_components.p, null, "Some inline math, coming right up. ", React.createElement(_components.span, {
    className: "math math-inline"
  }, "T_n = a + (n-1)d")), "\n", React.createElement(_components.h3, {
    id: "using-a-mathblock-explicitly"
  }, "Using a ", React.createElement(_components.code, null, "MathBlock"), " explicitly"), "\n", React.createElement(_components.p, null, "The MathBlock component can be used in two ways,\neither by passing a KaTeX string to the ", React.createElement(_components.code, null, "math"), " prop,\nor by including the KaTeX string in between the opening and closing tags."), "\n", React.createElement(_components.h4, {
    id: "via-the-math-prop"
  }, "Via the ", React.createElement(_components.code, null, "math"), " prop."), "\n", React.createElement(_components.p, null, "Input:"), "\n", "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-mdx",
    title: "index.mdx",
    numberLines: true
  }, "import { MathBlock } from \"./../src/components/MathBlock\";\n<MathBlock title=\"Arithmetic progression sum\" math=\"S_n = \\frac{n(a + l)}{2}\" />\n")), "\n", React.createElement(_components.p, null, "Output:"), "\n", React.createElement(MathBlock, {
    title: "Arithmetic progression sum",
    math: "S_n = \\frac{n(a + l)}{2}"
  }), "\n", React.createElement(_components.h4, {
    id: "via-children"
  }, "Via ", React.createElement(_components.code, null, "children"), "."), "\n", React.createElement(_components.p, null, "Input:"), "\n", "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-mdx",
    title: "index.mdx",
    numberLines: true
  }, "import { MathBlock } from \"./../src/components/MathBlock\";\n<MathBlock title=\"Arithmetic progression sum\">\n{\"S_n = \\\\frac{n(2a + (n-1)d)}{2}\"}\n</MathBlock>\n")), "\n", React.createElement(_components.p, null, "Output:"), "\n", React.createElement(MathBlock, {
    title: "Arithmetic progression sum"
  }, "S_n = \\frac{n(2a + (n-1)d)}{2}"), "\n", React.createElement(Aside, {
    variant: "danger"
  }, React.createElement(_components.p, null, "Notice I had to pass the math as a JavaScript string by opening and closing curly brackets:", React.createElement(_components.br), "\n", React.createElement(_components.code, null, "{\"math here\"}")), React.createElement(_components.p, null, "Because ", React.createElement(_components.code, null, "\\"), " is the escape character in JavaScript, but the LaTeX needs it, I had to escape it with an additional ", React.createElement(_components.code, null, "\\"), ":", React.createElement(_components.br), "\n", React.createElement(_components.code, null, "\\\\frac"))), "\n", React.createElement(_components.h3, {
    id: "using--signs-in-mdx"
  }, "Using ", React.createElement(_components.code, null, "$$"), " signs in mdx"), "\n", React.createElement(_components.h4, {
    id: "a-vanilla-block"
  }, "A vanilla block"), "\n", React.createElement(_components.p, null, "Input:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-mdx",
    title: "index.mdx",
    numberLines: true
  }, "$$\nS_n = \\frac{n(2a + (n-1)d)}{2}\n$$\n")), "\n", React.createElement(_components.p, null, "Output:"), "\n", React.createElement(_components.div, {
    className: "math math-display"
  }, "S_n = \\frac{n(2a + (n-1)d)}{2}"), "\n", React.createElement(_components.h4, {
    id: "with-a-meta-string"
  }, "With a meta string"), "\n", React.createElement(_components.p, null, "Input:"), "\n", "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-mdx",
    title: "index.mdx",
    numberLines: true
  }, "$$ title=Arithmetic-progression-sum\nS_n = \\frac{n(a + l)}{2}\n\\newline\nS_n = \\frac{n(2a + (n-1)d)}{2}\n$$\n")), "\n", React.createElement(_components.p, null, "Output:"), "\n", "\n", React.createElement(_components.div, {
    className: "math math-display",
    title: "Arithmetic-progression-sum"
  }, "S_n = \\frac{n(a + l)}{2}\n\\newline\nS_n = \\frac{n(2a + (n-1)d)}{2}"), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "Because of the method I chose to parse that string after the opening ", React.createElement(_components.code, null, "$$"), ",\nthe ", React.createElement(_components.code, null, "title"), " value is not able to have spaces in it.\nI could rectify this with a little effort,\nbut I’m happy with the way it is right now.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
